import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import GUI from 'dat.gui';

export default class PerfumeBottle {
    // constructor(scene, gui) GIA EXTRA GUI
    constructor(scene) {
        this.scene = scene;
        this.perfumeMesh = null;
        this.labelMesh = null;
        this.defaultTexture = new THREE.TextureLoader().load('./assets/perfume.webp');
        this.normalMapTexture = new THREE.TextureLoader().load('./assets/normalMap.png'); // Load the normal map
        // this.gui = gui;
        // this.lightControls = {
        //     directionalLightColor: '#7a6f63',
        //     pointLightColor: '#aaaaaa',
        //     spotLightColor: '#aaaaaa',
        //     directionalLightIntensity: 3,
        //     pointLightIntensity: 1.5,
        //     spotLightIntensity: 4.5,
        //     directionalLightPositionX: 1.16,
        //     directionalLightPositionY: 1.0,
        //     directionalLightPositionZ: 0.66,
        //     pointLightPositionX: 0.16,
        //     pointLightPositionY: 1.0,
        //     pointLightPositionZ: 1.0,
        //     spotLightPositionX: -2.54,
        //     spotLightPositionY: 3.12,
        //     spotLightPositionZ: 0.5
        // };
        // this.labelControls = {
        //     textColor: '#000000',
        //     labelPositionX: 0,
        //     labelPositionY: 0.036,
        //     labelPositionZ: 0.016,
        //     font: 'Tangerine',
        //     labelRoughness: 0.294,
        //     labelMetalness: 0,
        //     labelStrokeStyle: '#C0C0C0'
        // };
    }

    addBottle(perfumeImage, perfumeName) {
        const perfumeModelPath = './../../models/bottle/bottleFinal.glb';
        const gltfLoader = new GLTFLoader();
        gltfLoader.load(perfumeModelPath, (gltf) => {
            this.perfumeMesh = gltf.scene;
            this.scene.add(this.perfumeMesh);
            this.configureBottleMesh();
            this.addPerfumeNameLabel(perfumeName);
            // this.setupGUI();
        }, undefined, (error) => {
            console.error('An error happened while loading the model:', error);
        });
    }

    addPerfumeNameLabel(perfumeName) {
        const isMobile = window.innerWidth < 768;
        const fontSize = isMobile ? 45 : 45; // Adjust the font size to be more readable

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const textureSize = 1024;
        canvas.width = canvas.height = textureSize;

        context.fillStyle = '#000000';
        context.font = `${fontSize}px Yesteryear`;
        context.textAlign = 'center';
        context.textBaseline = 'alphabetic';
        context.lineWidth = 3; // Width of the stroke

        const words = perfumeName.split(' ');
        let line = '';
        const lines = [];
        const maxWidth = textureSize * 0.5; // Adjust the maxWidth to fit the text on the canvas

        for (let i = 0; i < words.length; i++) {
            const testLine = line + words[i] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && i > 0) {
                lines.push(line.trim());
                line = words[i] + ' ';
            } else {
                line = testLine;
            }
        }
        lines.push(line.trim());

        const lineHeight = fontSize + 9; // Adjust line height to ensure proper spacing
        const offsetY = textureSize / 2 - (lines.length * lineHeight) / 2;

        for (let i = 0; i < lines.length; i++) {
            context.strokeText(lines[i], textureSize / 2, offsetY + i * lineHeight);
            context.fillText(lines[i], textureSize / 2, offsetY + i * lineHeight);
        }

        const texture = new THREE.CanvasTexture(canvas);

        if (this.labelMesh) {
            this.labelMesh.material.map = texture;
            this.labelMesh.material.needsUpdate = true;
        } else {
            const labelGeometry = new THREE.PlaneGeometry(0.1, 0.2); // Adjust the plane geometry to fit the bottle
            const labelMaterial = new THREE.MeshStandardMaterial({
                map: texture,
                normalMap: this.normalMapTexture,
                transparent: true,
                side: THREE.DoubleSide,
                roughness: 0.350, // Adjust the roughness for a shiny effect
                metalness: 0.25 // Adjust the metalness for a metallic effect
            });
            this.labelMesh = new THREE.Mesh(labelGeometry, labelMaterial);
            this.labelMesh.position.set(0, 0.036, 0.016); // Position the label in front of the bottle
            this.perfumeMesh.add(this.labelMesh);
        }
    }

    configureBottleMesh() {
        if (!this.perfumeMesh) return;

        const isMobile = window.innerWidth < 768;

        if (isMobile) {
            this.perfumeMesh.scale.set(8, 8, 8);
            this.perfumeMesh.position.y = 1;
        } else {
            this.perfumeMesh.position.set(0, 0, 1);
        }

        this.perfumeMesh.castShadow = true;
        this.perfumeMesh.receiveShadow = true;
        this.perfumeMesh.rotation.x = 0.1;
        this.perfumeMesh.rotation.y = 0;
        this.perfumeMesh.scale.set(20, 20, 20);

        const directionalLight = new THREE.DirectionalLight('#ffffff', 4);
        directionalLight.castShadow = true;
        directionalLight.shadow.camera.far = 15;
        directionalLight.shadow.mapSize.set(1024, 1024);
        directionalLight.shadow.normalBias = 0.05;
        directionalLight.position.set(0.42, -0.5, 0.44);
        this.scene.add(directionalLight);

        const pointLight = new THREE.PointLight('#ffbf00', 2.94);
        this.scene.add(pointLight);
        pointLight.position.set(0.06, 0.3, 2.02);

        const spotLight = new THREE.SpotLight('#c3dad7', 10);
        spotLight.position.set(-2.54, 3.12, 0.5);
        this.scene.add(spotLight);
        spotLight.position.set(1.04, 4.24, 3.98);
    }
    // setupGUI() {
        // const lightsFolder = this.gui.addFolder('Lights');
        // lightsFolder.addColor(this.lightControls, 'directionalLightColor').onChange(color => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.DirectionalLight) {
        //             child.color.set(color);
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'directionalLightIntensity', 0, 10).onChange(intensity => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.DirectionalLight) {
        //             child.intensity = intensity;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'directionalLightPositionX', -10, 10).onChange(x => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.DirectionalLight) {
        //             child.position.x = x;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'directionalLightPositionY', -10, 10).onChange(y => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.DirectionalLight) {
        //             child.position.y = y;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'directionalLightPositionZ', -10, 10).onChange(z => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.DirectionalLight) {
        //             child.position.z = z;
        //         }
        //     });
        // });
        // lightsFolder.addColor(this.lightControls, 'pointLightColor').onChange(color => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.PointLight) {
        //             child.color.set(color);
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'pointLightIntensity', 0, 10).onChange(intensity => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.PointLight) {
        //             child.intensity = intensity;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'pointLightPositionX', -10, 10).onChange(x => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.PointLight) {
        //             child.position.x = x;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'pointLightPositionY', -10, 10).onChange(y => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.PointLight) {
        //             child.position.y = y;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'pointLightPositionZ', -10, 10).onChange(z => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.PointLight) {
        //             child.position.z = z;
        //         }
        //     });
        // });
    
        // Controls for spot light color, intensity, and position
        // lightsFolder.addColor(this.lightControls, 'spotLightColor').onChange(color => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.SpotLight) {
        //             child.color.set(color);
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'spotLightIntensity', 0, 10).onChange(intensity => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.SpotLight) {
        //             child.intensity = intensity;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'spotLightPositionX', -10, 10).onChange(x => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.SpotLight) {
        //             child.position.x = x;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'spotLightPositionY', -10, 10).onChange(y => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.SpotLight) {
        //             child.position.y = y;
        //         }
        //     });
        // });
        // lightsFolder.add(this.lightControls, 'spotLightPositionZ', -10, 10).onChange(z => {
        //     this.scene.children.forEach(child => {
        //         if (child instanceof THREE.SpotLight) {
        //             child.position.z = z;
        //         }
        //     });
        // });

        //LABEL MAKER



        // const labelFolder = this.gui.addFolder('Label');
        // labelFolder.addColor(this.labelControls, 'textColor').onChange(color => {
        //     this.labelMesh.material.color.set(color);
        // });
        // labelFolder.add(this.labelControls, 'labelPositionX', -1, 1).onChange(x => {
        //     this.labelMesh.position.x = x;
        // });
        // labelFolder.add(this.labelControls, 'labelPositionY', -1, 1).onChange(y => {
        //     this.labelMesh.position.y = y;
        // });
        // labelFolder.add(this.labelControls, 'labelPositionZ', -1, 1).onChange(z => {
        //     this.labelMesh.position.z = z;
        // });
        // // Adding font control if available
        // if (THREE.FontLoader) {
        //     const fonts = ['Tangerine', 'Arial', 'Helvetica', 'Times New Roman', 'CinzelDecorative-Black', 'Archivo Narrow'];
        //     labelFolder.add(this.labelControls, 'font', fonts).onChange(font => {
        //         if (font === 'CinzelDecorative-Black') {
        //             // Load ArchivoNarrow-Bold.ttf font file
        //             const fontUrl = './../../assets/CinzelDecorative-Black.ttf'; // Update with the actual path
        //             this.loadFont(fontUrl);
        //         } else {
        //             // Load other fonts here if needed
        //         }
        //     });
        // }
        // labelFolder.add(this.labelControls, 'labelRoughness', 0, 1).onChange(roughness => {
        //     this.labelMesh.material.roughness = roughness;
        // });
        // labelFolder.add(this.labelControls, 'labelMetalness', 0, 1).onChange(metalness => {
        //     this.labelMesh.material.metalness = metalness;
        // });
        // labelFolder.addColor(this.labelControls, 'labelStrokeStyle').onChange(strokeStyle => {
        //     this.updateLabelStroke(strokeStyle);
        // });
    // }


    ///////FONT LOADER CHANGER////
    


    // loadFont(fontName) {
    //     const fontLoader = new THREE.FontLoader();
    //     fontLoader.load(`./../../fonts/${fontName}.json`, font => {
    //         this.labelMesh.material.dispose(); // Dispose current material to release memory
    //         this.labelMesh.material = new THREE.MeshStandardMaterial({
    //             map: this.labelMesh.material.map,
    //             normalMap: this.normalMapTexture,
    //             transparent: true,
    //             side: THREE.DoubleSide,
    //             roughness: 1, // Adjust the roughness for a shiny effect
    //             metalness: 0, // Adjust the metalness for a metallic effect
    //             color: new THREE.Color(this.labelControls.textColor)
    //         });
    //         this.labelMesh.material.needsUpdate = true;
    //         this.labelMesh.geometry.dispose(); // Dispose current geometry to release memory
    //         this.labelMesh.geometry = new TextGeometry(perfumeName, {
    //             font: font,
    //             size: 0.1, // Adjust size if necessary
    //             height: 0.02 // Adjust height if necessary
    //         });
    //     });
    // }

    // updateLabelStroke(strokeStyle) {
    //     const context = this.labelMesh.material.map.image.getContext('2d');
    //     context.strokeStyle = strokeStyle;
    //     this.labelMesh.material.map.needsUpdate = true;
    // }

    _configureCamera() {
        const aspectRatio = window.innerWidth / window.innerHeight;
        this.camera.fov = aspectRatio < 1 ? 75 : 60;
        this.camera.position.set(0, 2, aspectRatio < 1 ? 6 : 5);
        this.camera.updateProjectionMatrix();
    }

    _setupEventListeners() {
        window.addEventListener('resize', () => {
            this._onWindowResize();
        });
    }

    _onWindowResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    adjustLabelSize() {
        const isMobile = window.innerWidth < 768;
        const scale = isMobile ? 1.5 : 1;
        const labelSize = isMobile ? 0.09 : 0.06;

        this.labelMesh.scale.set(scale, scale, scale);
        this.labelMesh.geometry = new THREE.PlaneGeometry(labelSize, labelSize);
    }

    removeBottle() {
        if (this.perfumeMesh && this.scene) {
            this.scene.remove(this.perfumeMesh);
        }
        if (this.labelMesh && this.scene) {
            this.scene.remove(this.labelMesh);
            this.labelMesh = null;
        }
    }

    tick(time) {
        if (this.perfumeMesh) {
            this.perfumeMesh.rotation.y = time * 0.7;
        }
    }
}
