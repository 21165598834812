import * as THREE from 'three';
import { gsap } from "gsap";

export default class Particles {
    constructor(props) {
        this.loader = props.loader;
        this.loadingManager = props.loadingManager; 
        this.particleMaterials = [];
        this.particleVertices = [];
        this.particles = [];

        this.configuration = {
            parameters: [
                [[ 1.0, 0.2, 0.5 ], 20 ],
                [[ 0.95, 0.1, 0.5 ], 15 ],
                [[ 0.90, 0.05, 0.5 ], 10 ],
                [[ 0.85, 0, 0.5 ], 8 ],
                [[ 0.80, 0, 0.5 ], 5 ]
            ],
            particleCount: 1000,
        }
        
        this._init();
    }

    _init() {
        this.particleGeometry = new THREE.BufferGeometry();
        this._loadSprites();
        this._generateVertices();
        this._generateMaterials();
        this._generatePoints();
        this.models = this.particles;
    }

    smokeAnimation() {
        // console.log("SMOKE");
        this.swirlTimeline = gsap.timeline();
        this.models.forEach((particle) => {
            // Scale up and down for an additional swirling effect
            this.swirlTimeline.to(particle.scale, {
                x: 2.0, y: 1.5, z: 0.5,
                duration: 1,
                yoyo: true,
                repeat: 1,
                ease: "sine.outIn"
            }, 0); // Start this animation at the beginning of the timeline
            this.swirlTimeline.to(particle.material, {
                opacity: 0,
                duration: 1, // Duration of the fade-out in seconds
                ease: "power2.inOut",
                onComplete: () => {
                    particle.visible = false; // Hide particles after fade-out
                    particle.material.opacity = 1; // Reset opacity for the next time it's shown
                },
            }, 0); // Start this animation at the beginning of the timeline
            
        });
        let tl = gsap.timeline();
        return tl;
    }

    tick(time) {
        this.models.forEach((particles, i) => {
            particles.rotation.y = time*( i < 4 ? i + 1 : - ( i + 1 ) );
        });
        this.particleMaterials.forEach((material, i) => {
            const color = this.configuration.parameters[i][0];
            const h = ( 360 * ( color[ 0 ] + time ) % 360 ) / 360;
            material.color.setHSL( h, color[ 1 ], color[ 2 ], THREE.SRGBColorSpace );
        });
    }

    _loadSprites() {
        this.loader = new THREE.TextureLoader(this.loadingManager);
        const sprite1 = this.loader.load( './assets/smoke1.webp', this.assignSRGB );
        const sprite2 = this.loader.load( './assets/smoke2.webp', this.assignSRGB );
        const sprite3 = this.loader.load( './assets/smoke3.webp', this.assignSRGB );
        const sprite4 = this.loader.load( './assets/smoke4.webp', this.assignSRGB );
        const sprite5 = this.loader.load( './assets/smoke2.webp', this.assignSRGB );
        this.sprites = [sprite2, sprite3, sprite1, sprite5, sprite4];
    }

    assignSRGB = ( texture ) => {
        texture.colorSpace = THREE.SRGBColorSpace;
    };

    _generateVertices() {
        const spread = { x: 150, y: 125, z: 100 };

        for (let i = 0; i < this.configuration.particleCount; i++) {
            // Spread particles across the volume by randomizing their positions
            const x = Math.random() * spread.x - spread.x / 2; // Spread from -500 to 500
            const y = Math.random() * spread.y - spread.y / 2; // Spread from -250 to 250
            const z = Math.random() * spread.z - spread.z / 2; // Spread from -500 to 500
        
            this.particleVertices.push(x, y, z);
        }
    }

    _generateMaterials() {
        this.configuration.parameters.forEach((parameter, i) => {
            const [color, size] = parameter;
            this.particleMaterials[i] = new THREE.PointsMaterial({ 
                size, 
                map: this.sprites[i], 
                blending: THREE.AdditiveBlending, 
                depthTest: false, 
                transparent: true  
            })
            this.particleMaterials[i].color.setHSL( color[ 0 ], color[ 1 ], color[ 2 ], THREE.SRGBColorSpace );
        });
    }

    _generatePoints() {
        this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(this.particleVertices, 3));
        // console.log(this.particleGeometry);
        this.particleMaterials.forEach((material) => {
            const particles = new THREE.Points(this.particleGeometry, material);
            particles.position.set(0, 0, 0); // Set the position of the particle system    
            this.particles.push(particles);
        });
    }
};