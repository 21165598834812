export default class CarouselComponent {
    constructor(catalogueController) {
        this.perfumesListElement = document.getElementById('perfumesList');
        this.catalogueController = catalogueController;
        this.index = 0;
        this.slideIntervalTime = 5000;
        this.autoSlideInterval = null;

        // DOM elements
        this.carousel = document.querySelector('.carousel');
        this.carouselImagesContainer = this.carousel.querySelector('.carousel-images');
        this.detailsTitle = this.carousel.querySelector('.details h2');
        this.detailsDescription = this.carousel.querySelector('.details p');
        this.learnMoreButton = this.carousel.querySelector('.btn.learn-more');
        this.buyNowButton = this.carousel.querySelector('.btn.buy-now');
        this.nextButton = this.carousel.querySelector('.next');
        this.prevButton = this.carousel.querySelector('.prev');

        // Bind 'this' to methods
        this.showNextImage = this.showNextImage.bind(this);
        this.showPrevImage = this.showPrevImage.bind(this);
        this.startAutoSlide = this.startAutoSlide.bind(this);
        this.stopAutoSlide = this.stopAutoSlide.bind(this);
        this.updateCarousel = this.updateCarousel.bind(this);
        this.detectSwipe = this.detectSwipe.bind(this);

        // Initialize carousel
        this.fetchCarouselData();
    }

    fetchCarouselData() {
        fetch('./get_carousel.php')
            .then(response => response.json())
            .then(perfumesData => {
                this.perfumesData = perfumesData;
                this.updateCarousel(this.index);
                this.startAutoSlide();
                this.addEventListeners();
            })
            .catch(error => {
                console.error('Error loading carousel data:', error);
            });
    }

    updateCarousel(index) {
        const perfume = this.perfumesData[index];
    
        const newImage = new Image();
        newImage.onload = () => {
            this.carouselImagesContainer.innerHTML = '';
            newImage.classList.add('carousel-image');
            newImage.style.opacity = 0;
            this.carouselImagesContainer.appendChild(newImage);
    
            setTimeout(() => {
                newImage.style.opacity = 1;
            }, 10);
        };
        newImage.src = perfume.image; // Ensure 'image' field is included in your table if needed
    
        this.detailsTitle.textContent = perfume.product;
        this.detailsDescription.textContent = perfume.notes;
        
        this.learnMoreButton.onclick = () => {
            this.catalogueController.displayPerfumes([perfume]);
            navigateToSection('catalogue');
            this.perfumesListElement.style.display = 'flex';
            setTimeout(() => {
                this.catalogueController.highlightSelectedPerfume(perfume.product);
            }, 500);
            console.log(perfume)
            console.log(perfume.product)
        };
    }

    closePopup() {
        const popup = document.getElementById('addToCartPopup');
        popup.classList.add('hidden');
    }

    showNextImage() {
        this.index = (this.index + 1) % this.perfumesData.length;
        this.updateCarousel(this.index);
    }

    showPrevImage() {
        this.index = (this.index - 1 + this.perfumesData.length) % this.perfumesData.length;
        this.updateCarousel(this.index);
    }

    startAutoSlide() {
        if (this.autoSlideInterval) {
            clearInterval(this.autoSlideInterval);
        }
        this.autoSlideInterval = setInterval(this.showNextImage, this.slideIntervalTime);
    }

    stopAutoSlide() {
        clearInterval(this.autoSlideInterval);
    }

    detectSwipe(el) {
        let startX, startY, endX, endY;
        const minDist = 50;
    
        el.addEventListener('touchstart', (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
            this.stopAutoSlide();
        });
    
        el.addEventListener('touchmove', (e) => {
            endX = e.touches[0].clientX;
            endY = e.touches[0].clientY;
        });
    
        el.addEventListener('touchend', () => {
            if (Math.abs(startX - endX) > minDist || Math.abs(startY - endY) > minDist) {
                if (endX < startX) {
                    this.showNextImage();
                } else {
                    this.showPrevImage();
                }
            }
            setTimeout(() => {
                this.startAutoSlide();
            }, 100);
        });
    }

    addEventListeners() {
        this.nextButton.addEventListener('click', this.showNextImage);
        this.prevButton.addEventListener('click', this.showPrevImage);
        this.carousel.addEventListener('mouseover', this.stopAutoSlide);
        this.carousel.addEventListener('mouseout', this.startAutoSlide);
        this.detectSwipe(this.carousel);
    }
}
