
import uniqueBaseNotes from '../../unique_base_notes.json';

export default class SearchByNotes {
    constructor(catalogueController) {
        this.perfumesListElement = document.getElementById('perfumesList');
        this.catalogueController = catalogueController;
        this.selectedNotes = [];
        this.setupEventListeners();
        this.populateBaseSelection();
    }

    setupEventListeners() {
        document.body.addEventListener('click', (event) => {
            if (event.target.id === 'searchBNotesButton') {
                this.searchBySelectedNotes.bind(this)();
            }
        });
    }

    populateBaseSelection() {
        const sortedBaseNotes = uniqueBaseNotes.sort((a, b) => a.localeCompare(b));

        const BaseNotesContainer = document.getElementById('baseSelection');
        BaseNotesContainer.innerHTML = '';
        sortedBaseNotes.forEach(note => {
            let button = document.createElement('button');
            button.textContent = note;
            button.setAttribute('data-base-note', note);
            button.classList.add('note-selection-button');
            button.onclick = () => this.toggleBaseNoteSelection(button);
            BaseNotesContainer.appendChild(button);
        });
    }

    toggleBaseNoteSelection(button) {
        const note = button.getAttribute('data-base-note');
        if (this.selectedNotes.includes(note)) {
            this.selectedNotes = this.selectedNotes.filter(n => n !== note);
            button.classList.remove('selected');
        } else {
            this.selectedNotes.push(note);
            button.classList.add('selected');
        }
    }

    searchBySelectedNotes() {
        if (this.selectedNotes.length < 2) {
            alert("Please select at least 2 notes.");
            return;
        }
        
        // Perform AJAX request to PHP script
        fetch(`searchBaseNotes.php?query=${encodeURIComponent(this.selectedNotes.join(','))}`)
            .then(response => response.json())
            .then(matchedPerfumes => {
                this.displaySearchResults(matchedPerfumes);
            })
            .catch(error => {
                console.error('Error searching:', error);
            });
    }

    displaySearchResults(matchedPerfumes) {
        const resultsContainer = document.getElementById('results');
        document.getElementById('results').style.display = 'flex';
        document.getElementById('results').style.visibility = 'visible';
        // Clear previous results
        resultsContainer.innerHTML = '';

        // Check if there are any results
        if (matchedPerfumes.length === 0) {
            resultsContainer.innerHTML = '<p>No matches found.</p>';
            return;
        }

        // Display new results
        matchedPerfumes.forEach(perfume => {
            const perfumeElement = document.createElement('div');
            perfumeElement.classList.add('perfume-result');

            const h5 = document.createElement('h5');
            h5.textContent = perfume.product;
            h5.addEventListener('click', () => {
                this.catalogueController.displayPerfumes([perfume]); // Assuming displayPerfumes accepts an array
                navigateToSection('catalogue'); // Ensure this navigates to the catalogue section
                this.perfumesListElement.style.display = 'flex'; // Show the list       
                setTimeout(() => {
                    this.catalogueController.highlightSelectedPerfume(perfume.product);
                }, 500);
            });

            perfumeElement.appendChild(h5);


            // Append the perfume element to the results container
            resultsContainer.appendChild(perfumeElement);
        });
    }
}
