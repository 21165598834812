import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { gsap } from "gsap";
// import GUI from 'lil-gui'
import Particles from '../particles';
import PerfumeBottle from '../threeMeshes/bottlemesh';
// import FirePaper from '../threeMeshes/firePaper';
export default class ThreeController {
    
    constructor() {
        // this.gui = new GUI();
        this.clock = new THREE.Clock()
        this.threeElements = [];


        this.init();
    }
//MISS: overlayMaterial
    init() {
        this._load();
        this._setupScene();
        this.perfumeBottle = new PerfumeBottle(this.scene); // Initialize the PerfumeBottle manager
        // this.perfumeBottle = new PerfumeBottle(this.scene, this.gui); // Initialize the PerfumeBottle manager  GIA EXTRA GUI
        

    }

    createParticles() {
        this.particles = new Particles({ loader: this.textureLoader, loadingManager: this.loadingManager });
        this.particles.models.forEach((model) => this.scene.add(model));
        this.threeElements.push(this.particles);
    }

    async addPerfumeBottle(perfumeImage, perfumeName) {
    // Ensure the font is loaded before creating the bottle
         await document.fonts.load('10pt "Tangerine"');

         // Check if the font is loaded (for debugging)
         console.log('Font loaded:', document.fonts.check('10pt "Tangerine"'));

         // Now that the font is loaded, create the perfume bottle
         if (!this.perfumeBottle) {
             this.perfumeBottle = new PerfumeBottle(this.scene);
         }
         this.perfumeBottle.addBottle(perfumeImage, perfumeName);
         this.threeElements.push(this.perfumeBottle);
    }
    
    
    removeCurrentPerfumeBottle() {
        if (this.perfumeBottle) {
            this.perfumeBottle.removeBottle();
        }
    }
    

    _load() {
        const loadingBarElement = document.querySelector('.loading-bar');
    
        const loadingManager = new THREE.LoadingManager(
            // Called when all resources are loaded
            () => {
                gsap.to('.loading-bar', { width: '100%', duration: 0.5, ease: "power2.inOut", onComplete: () => {
                    gsap.to('#loading-screen', { opacity: 0, duration: 0.5, ease: "power2.inOut", onComplete: () => {
                        document.getElementById('loading-screen').style.display = 'none';
                    }});
                }});
            },
            // Called while resources are in progress
            // Called while resources are in progress
            (itemUrl, itemsLoaded, itemsTotal) => {
                const progressRatio = (itemsLoaded / itemsTotal) * 100; // Convert to percentage
                loadingBarElement.style.width = progressRatio + '%';
                document.getElementById('loading-percentage').innerText = Math.round(progressRatio) + '%';
            }

            
        );

        this.loadingManager = loadingManager;
    }

    _revealContent() {
        const loadingScreen = document.getElementById('loading-screen');
        
        // Use GSAP for a smooth animation
        gsap.to(loadingScreen, {
            duration: 1.5,
            width: '0%',
            ease: "power4.inOut",
            onComplete: () => loadingScreen.style.display = 'none'
        });
    }
    _setupScene() {
        

        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.canvas = document.querySelector('canvas.webgl')
        this.scene = new THREE.Scene()
        this.textureLoader = new THREE.TextureLoader();
        this.fontLoader = new FontLoader();
        // this.firePaper = new FirePaper(this.scene, this.textureLoader, this.fontLoader);
        this.textureLoader.crossOrigin = '';
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        });
        this.camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
        this.scene.add(this.camera);

        this._configureCamera()
        this._configureRenderer(sizes);
        this._setControls();
    
        // console.log(this.particles);
        // this.testGLTF();
        this.test();
        this.tick();
    }
    
    
    smokeAnimation() {
        this.particles.smokeAnimation();
    }

    _setControls() {
        // // Controls
        const controls = new OrbitControls(this.camera, this.canvas)
        controls.enableDamping = true
    }

    test() {  //TODO
        const particleDirectionalLight = new THREE.DirectionalLight('#ffffff', 3)
        particleDirectionalLight.castShadow = true
        particleDirectionalLight.shadow.camera.far = 15
        particleDirectionalLight.shadow.mapSize.set(1024, 1024)
        particleDirectionalLight.shadow.normalBias = 0.05
        particleDirectionalLight.position.set(0.25, 3, - 2.25)
        this.scene.add(particleDirectionalLight)


        const partclePointLight = new THREE.PointLight('#f5c42b', 3)
        partclePointLight.castShadow = true
        partclePointLight.shadow.camera.far = 15
        partclePointLight.shadow.mapSize.set(1024, 1024)
        partclePointLight.shadow.normalBias = 0.05
        partclePointLight.position.set(0.25, 3, - 2.25)
        this.scene.add(partclePointLight)
    }

    

    _configureRenderer(sizes) {
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping
        this.renderer.toneMappingExposure = 0.55
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
        this.renderer.setSize(sizes.width, sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    _configureCamera() {
        this.camera.position.set(0, 2, 5)
        this.camera.near = 0.1; // Adjust as needed
        this.camera.far = 100; // Adjust as needed
        this.camera.updateProjectionMatrix(); // Update the camera's projection matrix
    }

    // createBottleMesh() {

    // }

    tickElements(time) { []
        this.threeElements.forEach((element) => {
            element.tick(time);
        });
    }

    tick() {
        let mouseX = 0, mouseY = 0;

        // const elapsedTime = clock.getElapsedTime();
        
        const time = Date.now() * 0.0005;
        this.tickElements(time);

        const elapsedTime = this.clock.getElapsedTime(); // Get the elapsed time since the ThreeController was created
        // this.firePaper.updateFireTime(elapsedTime);
        // if (!smokeComplete) {
            // createSmokeSwirl();
            // smokeComplete = true;
        // }
    
        // const time = Date.now() * 0.00005;

		this.camera.position.x += ( mouseX - this.camera.position.x ) * 0.05;
		this.camera.position.y += ( - mouseY - this.camera.position.y ) * 0.05;

		this.camera.lookAt( this.scene.position );
            // galaxyMaterial.uniforms.uTime.value = elapsedTime

        // Update controls
        // controls.update()


        // Render
        this.renderer.render(this.scene, this.camera)

        // Call tick again on the next frame
        requestAnimationFrame(this.tick.bind(this));
    }
}
