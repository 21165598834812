import Fuse from 'fuse.js';
import * as THREE from 'three'
import { generateGalaxy } from './components/galaxy/galaxy.js';
import ThreeController from './components/threeSetup/threeSetup.js';
import SearchByName from './components/search/SearchByName.js';
import CatalogueController from './components/search/CatalogueController.js';
import SearchByNotes from './components/search/SearchByNotes.js';
import SearchByMiddleNotes from './components/search/SearchByMiddleNotes.js';
import SearchByTopNotes from './components/search/SearchByTopNotes.js';
import SearchByBase from './components/search/SearchByBase.js';
import CartController from './components/cart/CartController.js';
import CarouselComponent from './components/threeMeshes/carousel.js';
// Import the countries.json file
import countriesData from './countries.json';

// Function to populate the country select
function populateCountrySelect(countries) {
    const countrySelect = document.getElementById('country');

    // Iterate over each country in the array
    countries.forEach(country => {
        // Create an option element
        const option = document.createElement('option');
        
        // Set the value and text of the option
        option.value = country.code;
        option.textContent = country.name;

        // Append the option to the select element
        countrySelect.appendChild(option);
    });
}



// Example usage


// Load countries from JSON file


const threeController = new ThreeController();
threeController.createParticles();



// threeController.smokeAnimation();


const catalogueController = new CatalogueController(threeController);


const searchByName = new SearchByName(catalogueController);
const searchByNotes = new SearchByNotes(catalogueController);
const searchByTopNotes = new SearchByTopNotes(catalogueController);
const searchByMiddleNotes = new SearchByMiddleNotes(catalogueController);
const searchByBase = new SearchByBase(catalogueController);


const carouselComponent = new CarouselComponent(catalogueController);

function openModal() {
    let modal = document.getElementById("imageViewer");
    modal.style.display = "block";
    // Add a small delay to allow the CSS transition to take effect
    setTimeout(() => {
        modal.style.backgroundColor = "rgba(0,0,0,0.9)"; // Set background color to opaque black
        modal.style.opacity = "1"; // Set opacity to fully opaque
    }, 50); // Adjust the delay as needed
}

// Close the modal with transition
function closeModal() {
    let modal = document.getElementById("imageViewer");
    modal.style.backgroundColor = "rgba(0,0,0,0)"; // Set background color to transparent
    modal.style.opacity = "0"; // Set opacity to fully transparent
    // Add a delay to allow the CSS transition to complete before hiding the modal
    setTimeout(() => {
        modal.style.display = "none";
    }, 300); // Match the transition duration in CSS
}

// Rest of your JavaScript code remains the same...


let slideIndex = 1;

function showSlides(n) {
    let slides = document.getElementsByClassName("about-image");
    if (n > slides.length) {slideIndex = 1}
    if (n < 1) {slideIndex = slides.length}
    document.getElementById("expandedImg").src = slides[slideIndex-1].src;
}

function plusSlides(n) {
    showSlides(slideIndex += n);
}
window.plusSlides = plusSlides;

// generateGalaxy(scene, renderer, clock)

// gui.add(galaxyParameters, 'count').min(100).max(1000000).step(100).onFinishChange(generateGalaxy)
// gui.add(galaxyParameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(generateGalaxy)
// gui.add(galaxyParameters, 'branches').min(2).max(20).step(1).onFinishChange(generateGalaxy)
// gui.add(galaxyParameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(generateGalaxy)
// gui.add(galaxyParameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy)
// gui.addColor(galaxyParameters, 'insideColor').onFinishChange(generateGalaxy)
// gui.addColor(galaxyParameters, 'outsideColor').onFinishChange(generateGalaxy)

    
//     const cameraFolder = gui.addFolder('spotLight');
//     cameraFolder.add(camera.position, 'x', -10, 10).name('Camera X');
//     cameraFolder.add(camera.position, 'y', -10, 10).name('Camera Y');
//     cameraFolder.add(camera.position, 'z', -10, 10).name('Camera Z');
//     cameraFolder.add(camera.rotation, 'x', -10, 10).name('Camera RX');
//     cameraFolder.add(camera.rotation, 'y', -10, 10).name('Camera RY');
//     cameraFolder.add(camera.rotation, 'z', -10, 10).name('Camera RZ');
// ///////GALAXY GUI////////////
// const galaxyFolder = gui.addFolder('Galaxy');
// const galaxyPosition = {
//     x: 0,
//     y: 0,
//     z: 0,
// };

// const galaxyRotation = {
//     rotationX: 0,
//     rotationY: 0,
//     rotationZ: 0,
// };

// // Position Controls
// galaxyFolder.add(galaxyPosition, 'x', -5, 5).onChange(value => {
//     galaxyPoints.position.x = value;
// });
// galaxyFolder.add(galaxyPosition, 'y', -5, 5).onChange(value => {
//     galaxyPoints.position.y = value;
// });
// galaxyFolder.add(galaxyPosition, 'z', -5, 5).onChange(value => {
//     galaxyPoints.position.z = value;
// });

// // Rotation Controls
// galaxyFolder.add(galaxyRotation, 'rotationX', -Math.PI, Math.PI).onChange(value => {
//     galaxyPoints.rotation.x = value;
// });
// galaxyFolder.add(galaxyRotation, 'rotationY', -Math.PI, Math.PI).onChange(value => {
//     galaxyPoints.rotation.y = value;
// });
// galaxyFolder.add(galaxyRotation, 'rotationZ', -Math.PI, Math.PI).onChange(value => {
//     galaxyPoints.rotation.z = value;
// });

// galaxyFolder.open();
//////////////////



// generateGalaxy()
// galaxyPoints.rotation.x = galaxyRotation.rotationX;
// galaxyPoints.rotation.y = galaxyRotation.rotationY;
// galaxyPoints.rotation.z = galaxyRotation.rotationZ;


/**
 * Animate
 */

///////////////////////////DOMS//////////////////////
///////////////////////////DOMS//////////////////////
///////////////////////////DOMS//////////////////////
///////////////////////////DOMS//////////////////////



document.addEventListener('DOMContentLoaded', function() {
    const language = getCookie('language') || 'eng';
    loadTranslations(language);
    // Set language cookie when language is selected
    document.querySelector('a[href="?language=eng"]').addEventListener('click', () => {
        document.cookie = "language=eng; path=/";
        loadTranslations('eng');
    });
    document.querySelector('a[href="?language=gr"]').addEventListener('click', () => {
        document.cookie = "language=gr; path=/";
        loadTranslations('gr');
    });
    function loadTranslations(language) {
        fetch(`/assets/translations-${language}.json`)
            .then(response => response.json())
            .then(translations => {
                document.querySelector('a[href="#home"]').textContent = translations.home;
            document.querySelector('a[href="#catalogue"]').textContent = translations.catalogue;
            document.querySelector('a[href="#perfumer"]').textContent = translations.perfumer;
            document.querySelector('a[href="#about"]').textContent = translations.about;
            document.querySelector('a[href="#contact"]').textContent = translations.contact;
            document.querySelector('a[href="#cart"]').textContent = translations.cart;

            // Update text content for section headers and descriptions
            document.querySelector('#center-panel h2').textContent = translations.original_creations;
            document.querySelector('#center-panel p').textContent = translations.discover_perfumes;
            document.querySelector('#left-panel h2').textContent = translations.new_catalogue;
            document.querySelector('#left-panel p').textContent = translations.discover_essence;
            document.querySelector('#left-panel a').textContent = translations.go_there_now;
            document.querySelector('#right-panel h2').textContent = translations.try_perfumer;
            document.querySelector('#right-panel p').textContent = translations.embark_journey;
            document.querySelector('#right-panel a').textContent = translations.to_the_perfumer;

            // Update text content for contact section
            document.querySelector('#center-panelC h2').textContent = translations.contact_us;
            document.querySelector('#center-panelC h3').textContent = translations.working_hours;
            document.querySelector('#center-panelC p').textContent = translations.paros_hours;
            document.querySelector('#contactForm label[for="name"]').textContent = translations.name;
            document.querySelector('#contactForm label[for="email"]').textContent = translations.email;
            document.querySelector('#contactForm label[for="message"]').textContent = translations.message;
            document.querySelector('#contactForm input[type="submit"]').value = translations.submit;
            document.querySelector('#thankYouMessage p').textContent = translations.thank_you;
            document.querySelector('#thankYouMessage button').textContent = translations.back_to_form;

            // Update text content for cart section
            document.querySelector('#cart h2').textContent = translations.your_cart;
            document.querySelector('#cartItems').textContent = translations.cart_items;
            document.querySelector('#cartTotal').textContent = translations.total;
            document.querySelector('#chcktBtn').textContent = translations.checkout;
            document.querySelector('#paymentMethodMessage p').textContent = translations.payment_method;
            document.querySelector('#bankTransferBtn').textContent = translations.bank_transfer;
            document.querySelector('#cardPaymentBtn').textContent = translations.card_payment;
            document.querySelector('#shippingCost').textContent = translations.shipping_cost;
            document.querySelector('#finalCost').textContent = translations.total_cost;

            // Update text content for searcher section
            document.querySelector('#searchInput').placeholder = translations.enter_name;
            document.querySelector('#initialChoice h2').textContent = translations.choose_preference;
            document.querySelector('#searchByNameBtn').textContent = translations.search_name;
            document.querySelector('#searchBySelectionBtn').textContent = translations.search_selection;
            document.querySelector('#noteTypeSelection button:nth-child(1)').textContent = translations.search_notes;
            document.querySelector('#noteTypeSelection button:nth-child(2)').textContent = translations.search_middle_notes;
            document.querySelector('#noteTypeSelection button:nth-child(3)').textContent = translations.search_top_notes;
            document.querySelector('#noteTypeSelection button:nth-child(4)').textContent = translations.search_base;

            // Update text content for catalogue section
            document.querySelector('.gender-selection button[data-gender="men"]').textContent = translations.men_perfumes;
            document.querySelector('.gender-selection button[data-gender="women"]').textContent = translations.women_perfumes;
            document.querySelector('.gender-selection button[data-gender="unisex"]').textContent = translations.unisex_perfumes;

            document.querySelector('.categoryType-selection button[data-category="candles"]').textContent = translations.aromatic_candles;
            document.querySelector('.categoryType-selection button[data-category="cstBody"]').textContent = translations.customized_body;
            document.querySelector('.categoryType-selection button[data-category="oliBody"]').textContent = translations.olivaloe_body;
            document.querySelector('.categoryType-selection button[data-category="carrier"]').textContent = translations.carrier_oils;
            document.querySelector('.categoryType-selection button[data-category="essential"]').textContent = translations.essential_oils;
            document.querySelector('.categoryType-selection button[data-category="autFreshers"]').textContent = translations.auto_freshers;
            document.querySelector('.categoryType-selection button[data-category="intFreshers"]').textContent = translations.interior_freshers;
            document.querySelector('.categoryType-selection button[data-category="moistSkcare"]').textContent = translations.moisturizer_skin;
            document.querySelector('.categoryType-selection button[data-category="soapanti"]').textContent = translations.soaps_antiseptics;
            document.querySelector('.categoryType-selection button[data-category="colognes"]').textContent = translations.colognes;
            
            // Update text content for about section
            document.querySelector('#about h3:nth-of-type(1)').textContent = translations.join_story;
            document.querySelector('#about p:nth-of-type(1)').textContent = translations.scented_story;
            document.querySelector('#about h2').textContent = translations.about_us;
            document.querySelector('#about p:nth-of-type(2)').textContent = translations.our_passion;
            document.querySelector('#about h3:nth-of-type(2)').textContent = translations.our_craft;
            document.querySelector('#about p:nth-of-type(3)').textContent = translations.our_craft_text;
            document.querySelector('#about h3:nth-of-type(3)').textContent = translations.essence_nature;
            document.querySelector('#about p:nth-of-type(4)').textContent = translations.essence_nature_text;

            })
            .catch(error => console.error('Error loading translations:', error));
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    populateCountrySelect(countriesData);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('status') === 'success') {
        document.getElementById('paymentSuccess').classList.remove('hidden');
        document.getElementById('orderId').textContent = urlParams.get('orderId');
        document.getElementById('orderDesc').textContent = urlParams.get('orderDesc');
        document.getElementById('orderAmount').textContent = urlParams.get('orderAmount');
        document.getElementById('payerEmail').textContent = urlParams.get('payerEmail');
    }
    navigateToSection('home');
    const navLinks = document.querySelectorAll('.nav-menu a');
    const panelLinks = document.querySelectorAll('.background-panel a');

    navLinks.forEach(link => {
        link.addEventListener('click', (e) => {
            const sectionId = e.target.getAttribute('href').slice(1); // Get the section id from href
            navigateToSection(sectionId);
            e.preventDefault(); // Prevent default anchor link behavior
        });
    });

    panelLinks.forEach(link => {
        link.addEventListener('click', (e) => {
            const sectionId = e.target.getAttribute('href').slice(1); // Get the section id from href
            navigateToSection(sectionId);
            e.preventDefault(); // Prevent default anchor link behavior
        });
    });
    
    var hamburger = document.querySelector('.hamburger');
    var navbar = document.querySelector('.navbar');
    var menuOverlay = document.createElement('div');
    menuOverlay.classList.add('menu-overlay');
    document.body.appendChild(menuOverlay);

    hamburger.addEventListener('click', function() {
        navbar.classList.toggle('open');
        menuOverlay.classList.toggle('active');
        this.classList.toggle('is-active');
    });

    menuOverlay.addEventListener('click', function() {
        navbar.classList.remove('open');
        menuOverlay.classList.remove('active');
        hamburger.classList.remove('is-active');
    });
    let images = document.getElementsByClassName("about-image");
    for (let i = 0; i < images.length; i++) {
        images[i].addEventListener('click', function() {
            document.getElementById("expandedImg").src = this.src;
            openModal();
            showSlides(slideIndex);
        });
    }

    document.getElementsByClassName("closeThis")[0].addEventListener('click', function() {
        closeModal();
    });

    const bankTransferForm = document.getElementById('bankTransferForm');
    if (bankTransferForm) {
        bankTransferForm.addEventListener('submit', (event) => {
            event.preventDefault(); // Prevent the default form submission behavior
        
            // Collect form data
            const formData = new FormData(bankTransferForm);
        
            // Send form data to a PHP script for processing
            fetch('./process_bank_transfer.php', {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                // Handle the response from the PHP script
                if (data.success) {
                    // Display a confirmation message
                    alert("Your order has been placed. Thank you!");
                } else {
                    alert("There was an error processing your order. Please try again later.");
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert("There was an error processing your order. Please try again later.");
            });
        });
    }

    const form = document.getElementById('contactForm');
    const thankYouMessage = document.getElementById('thankYouMessage');
    const formBack = document.getElementById('formBack');

    form.addEventListener('submit', function(e) {
        e.preventDefault();
        
        const formData = new FormData(form);

        fetch('./contact.php', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                alert(data.message);
                form.style.display = 'none';
                thankYouMessage.style.display = 'block';
            } else {
                alert(data.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('There was a problem submitting your form. Please try again later.');
        });
    });

    formBack.addEventListener('click', function() {
        form.style.display = 'block';
        thankYouMessage.style.display = 'none';
        form.reset();
    });


    const paymentForm = document.getElementById('paymentForm');
    const submitButton = document.getElementById('paymentSubmitButton');
  
    // Ensure the button is disabled after it's clicked once
    if (submitButton) {
      submitButton.addEventListener('click', function(event) {
        // Disable the button to prevent multiple submissions
        submitButton.disabled = true;
  
        // Optionally, you can add some visual feedback for the user
        submitButton.innerText = 'Processing...';
  
        // You can add further form validation or processing here
  
        // Submit the form
        paymentForm.submit();
      });
    }



    
});


/////////////////////////////////SEARCHER//////////////////////////

document.getElementById('searchByNameBtn').addEventListener('click', function() {
    // Hide the initial choice buttons and show the search bar
    document.getElementById('initialChoice').style.display = 'none';
    document.getElementById('nameSearch').style.display = 'flex';
    document.getElementById('nameSearch').classList.add('fade-in');

    // Show the back button
    document.getElementById('backBtn').style.display = 'block';
});


document.getElementById('searchBySelectionBtn').addEventListener('click', function() {
    document.getElementById('initialChoice').style.display = 'none';
    document.getElementById('scentSelection').style.display = 'flex'; // Show note type selection
    document.getElementById('backBtn').style.display = 'block';
});


setupNoteTypeSelection('searchByNotes', 'notesSelection', 'searchNotesButton');
setupNoteTypeSelection('searchByMiddleNotes', 'middleNotesSelection', 'searchMNotesButton'); // Assuming you have this div for middle notes
setupNoteTypeSelection('searchByTopNotes', 'topNotesSelection', 'searchTNotesButton');
setupNoteTypeSelection('searchByBase', 'baseSelection' , 'searchBNotesButton'); // Assuming you have this div for base notes


document.getElementById('backBtn').addEventListener('click', function() {
    // Hide everything except the initial choices
    document.getElementById('initialChoice').style.display = 'flex';
    document.getElementById('nameSearch').style.display = 'none';
    document.getElementById('searchNotesButton').style.display = 'none';
    document.getElementById('searchMNotesButton').style.display = 'none';
    document.getElementById('searchTNotesButton').style.display = 'none';
    document.getElementById('searchBNotesButton').style.display = 'none';
    document.getElementById('notesSelection').style.display = 'none';
    document.getElementById('middleNotesSelection').style.display = 'none'; // Ensure this is included
    document.getElementById('topNotesSelection').style.display = 'none';
    document.getElementById('baseSelection').style.display = 'none'; // Ensure this is included
    document.getElementById('scentSelection').style.display = 'none';
    document.getElementById('results').innerHTML = ''; // Optionally clear search results
    document.getElementById('results').style.visibility = 'hidden';

    // Show the note type selection buttons again
    document.getElementById('noteTypeSelection').style.display = 'flex'; // Reset this to be visible

    document.getElementById('backBtn').style.display = 'none'; // Hide the back button
});

function setupNoteTypeSelection(buttonId, selectionDivId, searchBtn) {
    document.getElementById(buttonId).addEventListener('click', function() {
        // Hide the note type selection buttons
        document.getElementById('noteTypeSelection').style.display = 'none';
        
        // Show the selected note type selection area
        document.querySelectorAll('.notesSelectionArea').forEach(function(div) {
            div.style.display = 'none'; // Hide all first
        });
        document.getElementById(selectionDivId).style.display = 'flex'; // Show the selected one
        document.getElementById(searchBtn).style.display = 'flex'; // Show the selected one

        // Adjust back button functionality if needed
    });
}
// Attach the resetSearch function to the window object
// Attach the functions to the window object


////////////NAVIGATE TO SECTIONS//////////////////
function navigateToSection(sectionId) {
    const currentlyVisibleSection = document.querySelector('.section.visible');
    const sectionToShow = document.getElementById(sectionId);
    
    if (threeController.perfumeBottle) {
        threeController.removeCurrentPerfumeBottle();
    }


    const perfumePopup = document.querySelector('.perfume-popup');
    if (perfumePopup) {
        perfumePopup.style.display = 'none';
    }

    // Check if the product-popup element is visible and hide it if it is
    const productPopup = document.querySelector('.product-popup');
    if (productPopup) {
        productPopup.style.display = 'none';
    }



    // Start the section fade-out immediately
    if (currentlyVisibleSection && currentlyVisibleSection.id !== sectionId) {
        currentlyVisibleSection.style.opacity = '0';
        currentlyVisibleSection.addEventListener('transitionend', function transitionOut() {
            this.classList.remove('visible');
            this.removeEventListener('transitionend', transitionOut);

            // Make particles invisible after the section is hidden
            threeController.particles.models.forEach(model => model.visible = false);

            // Show the new section
            showNewSection(sectionToShow);
        }, { once: true });
    } else if (sectionToShow && !currentlyVisibleSection) {
        // If there is no currently visible section, just show the new section
        showNewSection(sectionToShow);
    }

    // Make particles visible and play the animation
    threeController.particles.models.forEach(model => model.visible = true);
    threeController.particles.smokeAnimation();
    
    // Set a timeout to hide the particles after the animation plays
    setTimeout(() => {
        threeController.particles.models.forEach(model => model.visible = false);
    }, 1000); // Adjust based on the duration of your smokeAnimation
}


function showNewSection(sectionToShow) {
    if (sectionToShow) {
        // Ensure the display change is acknowledged before adjusting opacity
        requestAnimationFrame(() => {
            sectionToShow.style.opacity = '1';
            sectionToShow.classList.add('visible');

            // Show the note after a delay of 1 second
            setTimeout(() => {
                document.getElementById('note').style.display = 'block';
            }, 1000);
        });
    }
}


function closeNote() {
    document.getElementById('note').style.display = 'none';
}

window.navigateToSection = navigateToSection;
window.closeNote = closeNote;










//////////FILTER GENDER AND ACIDITY////////////////




//////////////////LOGIN START JAVASCRIPT MOCKED/////////////////

// function handleLogin(event) {
//     event.preventDefault();
    
//     const username = document.getElementById('username').value;
//     const password = document.getElementById('password').value;

//     // This is where you would send a request to your backend
//     // For now, we'll just mock this
//     const users = {
//         'jarjarA': 'obiwanTwo',
//         'jarjarB': 'obiwanThree'
//     };

//     if (users[username] === password) {
//         // Login successful
//         console.log('Login successful');
//         sessionStorage.setItem('isLoggedIn', 'true');
//         // Redirect to a new page or change the UI to reflect the logged-in status
//         window.location.href = '/dashboard'; // Mock redirect to a dashboard
        
//         alert('Welcome');
//     } else {
//         // Login failed
//         console.log('Login failed');
//         alert('Invalid username or password');
//     }
// }

// // Example logout function
// function handleLogout() {
//     sessionStorage.removeItem('isLoggedIn');
//     // Redirect to the homepage or change the UI to reflect the logged-out status
//     window.location.href = '/';
// }

// // Check if the user is logged in and update UI accordingly
// function checkLoginStatus() {
//     const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
//     // Update UI based on login status
// }

// // Call this function on page load and after login/logout
// checkLoginStatus();