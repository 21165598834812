export default class CartController {
    constructor() {
        this.cart = [];
        this.totalCost = 0;
        this.totalWeight = 0;
        this.shippingCosts = null; // Initialize the shippingCosts
        this.fetchShippingCosts(); // Fetch shipping costs when the controller is initialized
        this.finalCostValue = 0;
        this.paymentMethodSelected = false; // Flag to track if a payment method is selected
        this.init();
    }

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.setupEventListeners();
        });
    }

    setupEventListeners() {
        const chcktBtn = document.getElementById('chcktBtn');
        if (chcktBtn) {
            const paymentMethodMessage = document.getElementById('paymentMethodMessage');
            const bankTransferBtn = document.getElementById('bankTransferBtn');
            const cardPaymentBtn = document.getElementById('cardPaymentBtn');
            const cardPaymentForm = document.getElementById('purchaseForm');
    
            chcktBtn.addEventListener('click', () => {
                if (this.cart.length === 0) {
                    // If cart is empty, return without doing anything
                    alert("Your cart is empty. Please add items before proceeding to checkout.");
                    return;
                }
                // Display the payment method message and buttons
                paymentMethodMessage.style.display = 'block';
            });
    
            // Add event listener to the payment method buttons
            bankTransferBtn.addEventListener('click', () => {
                this.updateHiddenWeightInput();
                this.showCheckoutModal(); // Use arrow function to preserve 'this' context
                // Set form action to process_bank_transfer.php
                document.getElementById('paymentMethod').value = 'Bank'; // Set the value attribute
                document.getElementById('paymentForm').action = './process_bank_transfer.php';
                this.paymentMethodSelected = true; // Set the flag to true
            });
            
            cardPaymentBtn.addEventListener('click', () => {
                this.updateHiddenWeightInput();
                this.showCheckoutModal(); // Use arrow function to preserve 'this' context
                // Set form action to route.php?act=send
                document.getElementById('paymentMethod').value = 'Card'; // Set the value attribute
                document.getElementById('paymentForm').action = './route.php?act=send';
                this.paymentMethodSelected = true; // Set the flag to true
            });
            
            document.getElementById('paymentForm').addEventListener('submit', (event) => {
                // Prevent default form submission behavior
                event.preventDefault();
                
                // Check if a payment method is selected
                if (!this.paymentMethodSelected) {
                    alert('Please select a payment method before moving on.');
                    return;
                }
                
                // Perform any additional validation or processing if needed
                
                // Submit the form
                document.getElementById('paymentForm').submit();
            });
            
            
        }

        const closeButton = document.querySelector('.close-button-cart');
        if (closeButton) {
            closeButton.addEventListener('click', () => this.hideCheckoutModal());
        }

        window.addEventListener('click', (event) => {
            if (event.target === document.getElementById('checkoutModal')) {
                this.hideCheckoutModal();
            }
        });

        const countrySelect = document.getElementById('country');
        if (countrySelect) {
            countrySelect.addEventListener('change', () => {
                this.updateCheckoutModal();
            });
        }
    }
    convertKgToMl(kg) {
        return kg * 1000;
    }
    showAddToCartPopup(product) {
        const popup = document.createElement('div');
        popup.classList.add('cart-popup');
    
        // Check if product.product or product.productname is defined
        const productName = product.product || product.productname;
        if (!productName) {
            console.error('Product name not found in product object:', product);
            return;
        }
    
        popup.innerHTML = `<p>${productName} added to cart</p>`;
        document.body.appendChild(popup);
    
        setTimeout(() => {
            popup.remove();
        }, 3000);
    }
    

    addToCart(product, productPrice, productWeight, productScent, productFragrance, productDuration, brand, selectedWeightCategory) {
        // console.log(`1.Adding to cart: ${product}, Weight: ${productWeight}kg, name: ${product}kg, Price: €${productPrice}, Weight: ${productWeight}, Scent: ${productScent}, Fragrance: ${productFragrance}, Fragrance: ${productDuration}`);


        const productName = product.productname;
        const item = {
            ...product,
            productname: productName,
            weight: parseFloat(productWeight), 
            price: parseFloat(productPrice), 
            id: `${product.productid}`, 
            scent: productScent,
            fragrance:  productFragrance,
            duration:  productDuration,
            brand:  `${product.brand}` 
        };
        // console.log(item)
        if (selectedWeightCategory){
            item.selectedWeightCategory = selectedWeightCategory;

        }
        const projectedTotalWeight = this.cart.reduce((total, item) => total + (item.weight * item.quantity), 0) + item.weight;

        if (projectedTotalWeight > 2) {
            alert('Adding this item would exceed the weight limit of 2 kilos. Please adjust your cart.');
            return;
        }
        // console.log(`2.Adding to cart: ${product}, Weight: ${productWeight}kg, Price: €${productPrice}, Scent: ${productWeight}, Fragrance: ${productFragrance}`);

        this.processCartItem(item);
    }

    processCartItem(item) {
        if (item.brand){
            // console.log(item.productname)
            item.brand = item.brand
        } 
        if (item.productname){
            // console.log(item.productname)
            item.product = item.productname
        } 
        const itemID = `${item.productid}-${item.weight}`;
        item.id = itemID;

        const existingItem = this.cart.find(cartItem => cartItem.id === item.id);

        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            this.cart.push({ ...item, quantity: 1 });
        }
        // console.log(item)

        this.updateCartDisplay();
        this.showAddToCartPopup(item);
    }

    updateCartDisplay() {
        const cartItemsElement = document.getElementById('cartItems');
        cartItemsElement.innerHTML = ''; // Clear existing cart items
        let totalCost = 0;
        let totalWeight = 0; // Initialize total weight
        
        this.cart.forEach((item, index) => {
            // Accumulate total weight for shipping calculation
            totalWeight += item.weight * item.quantity;
            totalCost += item.price * item.quantity;
            let productName;
            if (item.weight === 0.007 || item.weight === 0.014) {
                // Modify the productName
                productName = `${item.product} (Pure)`;
            } else {
            
                // Check if it's a candle
                if (item.duration) {
                    productName = `${item.product} (${item.duration}h)`;
                } else {
                    // Handle the case where selectedWeight is not recognized
                    productName = `${item.product} ${item.weight}`;
                }
            }
            
            if (item.scent) { // Only append "with scent" if the scent is actually defined
                productName = `${item.product} with ${item.scent}`;
            }
            
            if (item.fragrance){
                productName += `${item.product}with ${item.fragrance}`;
            }
            const itemElement = document.createElement('div');
            itemElement.classList.add('cart-item');
            let displayWeight;
            if (item.selectedWeightCategory) {
                displayWeight = item.selectedWeightCategory; // Use weightCategory for display
                console.log(item.selectedWeightCategory);
            } else {
                displayWeight = `${item.weight}kg`;
            }
            itemElement.innerHTML = `
                <span class="cart-item-name">${productName}</span> x 
                <span class="cart-item-quantity">${item.quantity}</span>
                <span class="cart-item-price">\u20AC${(item.price * item.quantity).toFixed(2)}</span>
                <span class="cart-item-weight">${displayWeight}</span>
                <button class="remove-item" data-index="${index}">Remove</button>
            `;
            cartItemsElement.appendChild(itemElement);

            itemElement.querySelector('.remove-item').addEventListener('click', () => this.removeFromCart(index));
        });

        const weightInfoElement = document.getElementById('weightInfo');
        weightInfoElement.textContent = `Total Weight: ${totalWeight.toFixed(2)} kg.`;
        
        const weightIcon = document.getElementById('weightIcon');
        if (totalWeight > 0) {
            weightIcon.style.display = 'inline'; // Make the icon visible if there's any weight
            // Optionally, change the icon or its style based on the weight
            // For example, changing the icon color if close to the limit:
            if (totalWeight > 1.8) { // Example threshold, adjust as needed
                weightIcon.style.color = 'red'; // Change color to red if close to the limit
            } else {
                weightIcon.style.color = 'black'; // Default color
            }
        } else {
            weightIcon.style.display = 'none'; // Hide the icon if the cart is empty
        }
        document.getElementById('cartTotal').textContent = `Cost: \u20AC${totalCost.toFixed(2)}`;
        this.totalCost = totalCost;
        this.totalWeight = totalWeight;
        this.updateCheckoutModal();
    }

    updateCheckoutModal() {
        const countrySelect = document.getElementById('country');
        let shippingCost = this.calculateShippingCost(countrySelect ? countrySelect.value : "GR");

        document.getElementById('modalCartTotal').textContent = `Base Total: €${this.totalCost.toFixed(2)}`;
        document.getElementById('modalCartWeight').textContent = `Total Weight: ${this.totalWeight.toFixed(2)} kg`;
        document.getElementById('shippingCost').textContent = `Shipping Cost: €${shippingCost.toFixed(2)}`;
        this.finalCostValue = this.totalCost + shippingCost;
        console.log(this.finalCostValue);

        document.getElementById('finalCost').textContent = `Total with Shipping: €${this.finalCostValue.toFixed(2)}`;
        document.getElementById('orderAmount').value = this.finalCostValue.toFixed(2); // Set the value attribute


    }

    showCheckoutModal() {
        if (this.cart.length === 0) {
            alert("Your cart is empty. Please add items before proceeding to checkout.");
            return;
        }
        const orderedItems = this.cart.map(item => {
            return {
                productName: item.product,
                fragrance: item.fragrance,
                duration: item.duration,
                scent: item.scent,
                quantity: item.quantity,
                price: item.price,
                weight: item.weight,
                brand: item.brand
            };
        });
    
        // Store or process the orderedItems as needed
        console.log("Ordered Items:", orderedItems);
        this.updateCheckoutModal();
        document.getElementById('checkoutModal').style.display = 'block';
    
        const orderId = Math.floor(Math.random() * 1000000000000);
    
        document.getElementById('orderIdInput').value = orderId;
        const orderDesc = orderedItems.map(item => {
            const brandDesc = item.brand ? ` - ${item.brand}` : '';
            const scentDesc = item.scent ? ` - ${item.scent}` : '';
            const fragranceDesc = item.fragrance ? ` - ${item.fragrance}` : '';
            return `${item.productName} - ${item.quantity} - ${item.weight}${brandDesc}${scentDesc}${fragranceDesc}`;
        }).join(', ');
        // Set the value of the orderDescInput input element
        document.getElementById('orderDescInput').value = orderDesc;
            
        // Verify the value by logging it
        console.log(orderDesc);
    }
    

    hideCheckoutModal() {
        document.getElementById('checkoutModal').style.display = 'none';
    }
    async fetchShippingCosts() {
        return fetch('./get_shipping_cost.php')
            .then(response => response.json())
            .then(data => {
                this.shippingCosts = data;
            });
    }
    
    calculateShippingCost(country) {
        if (!this.shippingCosts) {
            console.error("Shipping costs data not loaded.");
            return 0;
        }
    
        if (country === "GR") {
            const grShipping = this.shippingCosts.find(cost => cost.country === "GR");
            return this.totalCost > 70 ? 0 : parseFloat(grShipping.price);
        } else {
            let weightCategory;
            if (this.totalWeight < 0.470) {
                weightCategory = "ElseLow";
            } else if (this.totalWeight < 0.950) {
                weightCategory = "ElseMid";
            } else if (this.totalWeight <= 2) {
                weightCategory = "ElseBig";
            }
    
            const otherShipping = this.shippingCosts.find(cost => cost.weightcategory === weightCategory);
            return otherShipping ? parseFloat(otherShipping.price) : 0;
        }
    }
    updateHiddenWeightInput() {
        const totalWeight = this.totalWeight.toFixed(2);
        document.getElementById('hiddenWeightInput').value = totalWeight;
    }
    

    removeFromCart(index) {
        this.cart.splice(index, 1);
        this.updateCartDisplay();
    }
}
