import CartController from '../cart/CartController';
import fragrance from "../../unique_fragrances.json";

export default class CatalogueController {
    constructor(threeController) {
        // this.gui = new GUI(); // If you want to add debug tools or filters.
        this.categorySelection = document.querySelector('.categoryType-selection');
        this.genderSelection = document.querySelector('.gender-selection');
        this.perfumesListElement = document.getElementById('perfumesList');
        this.cartController = new CartController(); // Instantiate CartController
        this.threeController = threeController;
        this.popupElement = null;
        // Initialize Fuse.js with your data and options
        
        this.init();
    }

    init() {
        this.bindUIActions();
        this.initBackToTopButton();
        // this.displayAllPerfumes(); // Optionally, if you want to show all perfumes from the start.
    }

    bindUIActions() {
        // Scent filtering
        document.querySelectorAll('.categoryType-selection button').forEach(btn => {
            btn.addEventListener('click', event => {
                const categorySelect = event.target.getAttribute('data-category');
                this.filterCategories(categorySelect);
                this.perfumesListElement.style.display = 'flex'; // Show the list
            });
        });
    
        // Example for gender selection buttons
        document.getElementById('menPerfumesBtn').addEventListener('click', () => {
            this.filterPerfumesByGender('men');
            this.perfumesListElement.style.display = 'flex'; // Show the list
        });
        document.getElementById('womenPerfumesBtn').addEventListener('click', () => {
            this.filterPerfumesByGender('women');
            this.perfumesListElement.style.display = 'flex'; // Show the list
        });
        document.getElementById('unisexPerfumesBtn').addEventListener('click', () => {
            this.filterPerfumesByGender('unisex');
            this.perfumesListElement.style.display = 'flex'; // Show the list
        });
        // Add similar logic for any other buttons/categories you have
    }
    
    convertKgToMl(kg) {
        return kg * 1000;
    }
    
    filterCategories(categorySelect) {
        const categoryMap = {
            'candles': 'Aromatic Candles',
            'cstBody': 'Customised Body Care Products',
            'oliBody': 'Olivaloe Body Care Products',
            'carrier': 'Carrier Oils',
            'essential': 'Essential Oils',
            'autFreshers': 'Auto Freshers',
            'intFreshers': 'Interior Freshers',
            'moistSkcare': 'Moisturizer Skin Creams',
            'colognes': 'Colognes',
            'soapanti': 'Soaps'
        };
        
    
        const categoryName = categoryMap[categorySelect]; // Get the category name from the map
    
        // Make a fetch request to get products based on the selected category
        fetch(`./get_products.php?category=${categoryName}`) // Use categoryName instead of categorySelect
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(productsData => {
                if (productsData.length > 0) {
                    // Map over the category's products to include the category name with each product
                    const productsWithCategory = productsData.map(product => ({
                        category: categoryName, // Include the selected category name
                        productname: product.productname, // Adjusted to access the Name key
                        image: product.image, // Added to include the image path
                        price: product.price, // Include the product price
                        weight: product.weight, // Include the product weight
                        productid: product.productid // Include the product weight
                    }));
    
                    this.displayProducts(productsWithCategory);
                } else {
                    console.error('No products found for this category:', categoryName);
                    this.perfumesListElement.innerHTML = '<p>No products found for this category.</p>';
                }
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                this.perfumesListElement.innerHTML = '<p>Error fetching products. Please try again later.</p>';
            });
            
            const perfumesEndpoint = './get_perfumes.php'; // Adjust the endpoint as needed

            fetch(perfumesEndpoint)
            .then(response => response.json())
            .then(perfumesData => {
                // Process and store the perfumes data for later use
                this.perfumesData = perfumesData;
            })
            .catch(error => {
                console.error('Error loading perfumes data:', error);
            });
    
    }
    
    
    
    displayProducts(products) {
        // Ensure the input is an array
        products = Array.isArray(products) ? products : [products];
        
        // Clear the current content of the perfumes list element
        this.perfumesListElement.innerHTML = '';
        
        // Create a document fragment to optimize DOM manipulation
        const fragment = document.createDocumentFragment();
        
        // Iterate over the products and create an element for each
        products.forEach((product, index) => {
            const productElement = this.createProductElement(product, index);
            fragment.appendChild(productElement);
        });
        
        // Append the document fragment to the perfumes list element
        this.perfumesListElement.appendChild(fragment);
    }
    createProductElement(product, index) {
        // Check if the product data is defined
        if (!product) {
            console.error('Product data is undefined.');
            return document.createElement('div'); // Return an empty div or some placeholder
        }
        
        // Create the container element for the product
        const element = document.createElement('div');
        element.classList.add('product-item');
        element.classList.add(`product-${index}`); // Use index for unique class
        element.setAttribute('data-product', product.productname);
        element.setAttribute('data-category', product.category); // Store the category

        const defaultImage = './assets/perfumer.webp'; // Path to your default image
        const imageUrl = product.image ? product.image : defaultImage;
    
        const img = new Image();
        img.src = imageUrl;
        img.onerror = () => {
            img.src = defaultImage; // Set default image if loading fails
        };
    
        img.onload = () => {
            element.style.backgroundImage = `url(${img.src})`;
            element.style.backgroundSize = 'cover'; // Optional: To cover the entire element
            element.style.backgroundPosition = 'center'; // Optional: To center the image
        };
    

        // Populate the element with product details
        element.innerHTML = `
            <div class="product-info">${product.productname}</div>
        `;
        
        // Add an event listener for when the product is clicked
        element.addEventListener('click', () => this.showProductDetails(product));
        
        return element;
    }

    showProductDetails(product) {
        // Show a Three.js popup with the perfume details
        // Call the Three.js function to create the scene with the perfume image
        this.createProductPopup(product, this.threeController)
    }
    createProductPopup(product) {
        // Initialize the popup
        const productPopup = document.createElement('div');
        productPopup.classList.add('product-popup');
    
        // Variables to customize based on the product's category
        let additionalInfo = '';
        let optionsHtml = '';
    
        // Fetch perfumes data from the backend API endpoint
        const perfumesEndpoint = './get_perfumes.php';
        fetch(perfumesEndpoint)
            .then(response => response.json())
            .then(perfumesData => {
                // Process and store the perfumes data for later use
                this.perfumesData = perfumesData;
    
                // Generate options HTML based on perfumes data
                const productOptions = this.perfumesData.map(perfume => `<option value="${perfume.product}">${perfume.product}</option>`).join('');
                const fragranceOptions = fragrance.map(p => `<option value="${p.Name}">${p.Name}</option>`).join('');
                const pricesEndpoint = `./get_prices.php?category=${product.category}`;
                fetch(pricesEndpoint)
                    .then(response => response.json())
                    .then(pricesData => {
                        // Process and store the prices data for later use
                        // Assuming pricesData is an array of objects with 'weight' and 'price' properties
                        const priceOptions = pricesData.map(price => `
                            <label><input type="radio" name="weight" value="${price.weight}" data-price="${price.price}" data-duration="${price.duration}">${price.duration} hours - ${price.price}€</label>
                        `).join('');
                        const priceOptionsCologne = pricesData.map(price => `
                            <label><input type="radio" name="weight" value="${price.weight}" data-price="${price.price}">${price.weight}kg- ${price.price}€</label>
                        `).join('');
                        // Determine content based on category
                        switch (product.category) {
                            case 'Aromatic Candles':
                                additionalInfo = `<p>Light up your space with our aromatic candles.</p>`;
                                optionsHtml = priceOptions; // Use the dynamically generated price options HTML
                                break;
                    case 'Customised Body Care Products':
                        additionalInfo = `<p>Give your own essence to your ${product.productname}.</p>`;
                        // Assume options for Customised Body Care Products
                        optionsHtml = `
                            <label>Choose your scent:
                            <select name="productChoice" id="productChoice">
                                    ${productOptions}
                                </select>
                            </label>
                        `;
                        break;
                    case 'Olivaloe Body Care Products':
                        additionalInfo = `<p>Nourish your skin with the natural touch of Olivaloe.</p>`;
                        optionsHtml = `
                            <label>Price:
                                <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                        break;
                    case 'Carrier Oils':
                        additionalInfo = `<p>Find the perfect carrier for your essential oils.</p>`;
                        optionsHtml = `
                            <label>Price:
                                <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `;
                        break;
                    case 'Essential Oils':
                        additionalInfo = `<p>Essential oils for every mood and moment.</p>`;
                        optionsHtml = `
                            <label>Price:
                                <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                        break;
                    case 'Auto Freshers':
                        additionalInfo = `<p>Keep your car smelling fresh with our auto freshers.</p>`;
                        if (product.productname.includes('with Perfume')) {
                            additionalInfo = `<p>Choose your scent:</p>
                                                <select name="productChoice" id="productChoice">
                                                    ${productOptions}
                                                </select>`;
                        } else if (product.productname.includes('with Fragrance')) {
                            additionalInfo = `<p>Choose your fragrance:</p>
                                                <select name="fragranceChoice" id="fragranceChoice">
                                                    ${fragranceOptions}
                                                </select>`;
                        }
                        optionsHtml = `
                            <label>Price:
                                <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                        break;
                    case 'Interior Freshers':
                        additionalInfo = `<p>Refresh your home with our interior fresheners.</p>`;
                        if (product.productname.includes('with Perfume')) {
                            additionalInfo = `<p>Choose your scent:</p>
                                                <select name="productChoice" id="productChoice">
                                                    ${productOptions}
                                                </select>`;
                        } else if (product.productname.includes('with Fragrance')) {
                            additionalInfo = `<p>Choose your fragrance:</p>
                                                <select name="fragranceChoice" id="fragranceChoice">
                                                    ${fragranceOptions}
                                                </select>`;
                        }
                        
                        optionsHtml = `
                            <label>Price:
                                <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                        break;
                    case 'Moisturizer Skin Creams':
                        additionalInfo = `<p>Hydrate and protect your skin with our moisturizing creams.</p>`;
                        optionsHtml = `
                            <label>Price:
                            <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                        break;
                    case 'Soaps & Antiseptics':
                        additionalInfo = `<p>Cleanse and protect with our soaps and antiseptics.</p>`;
                        optionsHtml = `
                            <label>Price:
                            <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                        break;
                        case 'Colognes':
                        additionalInfo = `<p>Discover Our Exclusive Collection of Colognes</p>`;
                        optionsHtml = priceOptionsCologne; // Use the dynamically generated price options HTML

                        break;
                    default:
                        additionalInfo = `<p>Discover our exclusive range of ${product.category}.</p>`;
                        optionsHtml = `
                            <label>Price:
                            <h2>${product.price}€ per ${product.weight}kg</h2>
                            </label>
                        `
                }
    
                // Set the popup content
                productPopup.innerHTML = `
                    <div class="popup-close">✖</div>
                    <div class="popup-info">
                        ${additionalInfo}
                        <h2>${product.productname}</h2>
                        <div class="options-selection">${optionsHtml}</div>
                        <button class="btn add-to-cart">Add to Cart</button>
                    </div>
                `;
    
                // Handle "Add to Cart" button click
                const confirmButton = productPopup.querySelector('.add-to-cart');
            confirmButton.onclick = () => {
                // Get selected values
                let productWeight = product.weight;
                let productPrice = product.price;
                let productScent = null;
                let productFragrance = null;
                let productDuration = null;
                
                const selectedWeightElement = productPopup.querySelector('input[name="weight"]:checked');
                if (selectedWeightElement) {
                    productWeight = selectedWeightElement.value;
                    productPrice = selectedWeightElement.getAttribute('data-price');
                    productDuration = selectedWeightElement.getAttribute('data-duration');
                }

                const selectedProductElement = productPopup.querySelector('select[name="productChoice"]');
                if (selectedProductElement) {
                    productScent = selectedProductElement.value;
                }

                const selectedFragranceElement = productPopup.querySelector('select[name="fragranceChoice"]');
                if (selectedFragranceElement) {
                    productFragrance = selectedFragranceElement.value;
                }

                // Add the selected product to the cart
                this.cartController.addToCart(product, productPrice, productWeight, productScent, productFragrance, productDuration, null, null);
            };

            productPopup.style.display = 'block';
            // Handle closing the popup
            const closeButton = productPopup.querySelector('.popup-close');
            closeButton.addEventListener('click', () => {
                document.body.removeChild(this.popupElement);
                this.popupElement = null;
            });

            // Replace the existing popup element if any, or add the new one
            if (this.popupElement) {
                document.body.replaceChild(productPopup, this.popupElement);
            } else {
                document.body.appendChild(productPopup);
            }

            // Update the current popup element reference
            this.popupElement = productPopup;
        })
        })
            .catch(error => {
                console.error('Error loading perfumes data:', error);
            });
    }
    
    
    
    

    filterPerfumesByGender(gender) {
        let perfumesEndpoint = './get_perfumes.php'; // Default endpoint for no gender specification
    
        // Construct the endpoint URL based on the selected gender
        if (gender === 'men') {
            perfumesEndpoint += '?gender=men';
        } else if (gender === 'women') {
            perfumesEndpoint += '?gender=women';
        } else if (gender === 'unisex') {
            perfumesEndpoint += '?gender=unisex';
        }
    
        // Fetch perfumes data from the backend API endpoint
        fetch(perfumesEndpoint)
            .then(response => response.json())
            .then(perfumesData => {
                // Process and display the filtered perfumes data
                // You can reuse existing methods or create new ones as needed
                this.displayPerfumes(perfumesData);
            })
            .catch(error => {
                console.error('Error loading perfumes data:', error);
                // Handle errors, such as displaying a message to the user
            });
    }
    
    
    
    
    
    
    
    displayPerfumes(filteredData) {
        filteredData = Array.isArray(filteredData) ? filteredData : [filteredData];
        this.perfumesListElement.innerHTML = ''; // Previous logic follows
        filteredData.forEach((perfume, index) => {
            const perfumeElement = this.createPerfumeElement(perfume, index);
            this.perfumesListElement.appendChild(perfumeElement);
        });
    }
    createPerfumeElement(perfume, index) { // Accept index as a parameter
        if (!perfume) {
            console.error('Perfume data is undefined.');
            return document.createElement('div'); // Return an empty div or some placeholder
        }
    
        const element = document.createElement('div');
        element.classList.add('perfume-item');
        element.classList.add(`perfume-${index}`); // Now index is defined
        element.setAttribute('data-product', perfume.product);
        element.innerHTML = `
            <div class="perfume-info">${perfume.product || 'Unknown Product'}</div>
        `;
        element.addEventListener('click', () => this.showPerfumeDetails(perfume));
        return element;
    }


    showPerfumeDetails(perfume) {
        // Show a Three.js popup with the perfume details
        // Call the Three.js function to create the scene with the perfume image
        this.createPopup(perfume, this.threeController)
    }

    createPopup(perfume) {
        const rotationSpeed = 0.005;
        let notesHtml = [];
    
        // For each type of note, check if it exists and is not null, then add the corresponding HTML to the notesHtml array
        if (perfume['year']) {
            notesHtml.push(`<strong>Year:</strong> ${perfume['year']}`);
        }
        if (perfume.notes) {
            notesHtml.push(`<strong>Notes:</strong> ${perfume.notes}`);
        }
        if (perfume.note) {
            notesHtml.push(`<strong>Note:</strong> ${perfume.note}`);
        }
        if (perfume.middlenotes) {
            notesHtml.push(`<strong>Middle Notes:</strong> ${perfume.middlenotes}`);
        }
        if (perfume.mainnotes) {
            notesHtml.push(`<strong>Main Notes:</strong> ${perfume.mainnotes}`);
        }
        if (perfume.topnotes) {
            notesHtml.push(`<strong>Top Notes:</strong> ${perfume.topnotes}`);
        }
        if (perfume.aromaticnotes) {
            notesHtml.push(`<strong>Aromatic Notes:</strong> ${perfume.aromaticnotes}`);
        }
        if (perfume.base) {
            notesHtml.push(`<strong>Base:</strong> ${perfume.base}`);
        }
        if (perfume.contains) {
            notesHtml.push(`<strong>Contains:</strong> ${perfume.contains}`);
        }
    
        // Join the array elements into a single string, separated by line breaks
        let notesContent = notesHtml.join('<br>');


        const pricesEndpoint = `./get_prices.php?category=Perfumes`;
                fetch(pricesEndpoint)
                    .then(response => response.json())
                    .then(pricesData => {
                        // Process and store the prices data for later use
                        // Assuming pricesData is an array of objects with 'weight' and 'price' properties

                        const perfumeOptionsHtml = pricesData.map(price => {
                            const displayWeight = price.weightcategory; // Convert kg to ml for display
                            let productLabel = `${displayWeight} - ${price.price}€`;
                            
                            // Add "Pure" tag if weight is less than or equal to 0.014
                            if (price.weight <= 0.014 + Number.EPSILON) {
                                productLabel = `${displayWeight} (Pure) - ${price.price}€`;
                            }
            
                            return `<label><input type="radio" name="weight" value="${price.weight}" data-price="${price.price}" data-weightcategory="${price.weightcategory}">${productLabel}</label>`;
                        }).join('');



        // Create a new HTML element for the popup
        const newPopupElement = document.createElement('div');
        newPopupElement.classList.add('perfume-popup');
        newPopupElement.innerHTML = `
            <div class="popup-close">✖</div>
            <div class="popup-info">
                <h2>${perfume.product}</h2> 
                ${notesContent}
                <div class="weight-selection">
                    ${perfumeOptionsHtml}
                </div>
                <button class="btn add-to-cart">Add to Cart</button>
            </div>
        `;
    
        const confirmButton = newPopupElement.querySelector('.add-to-cart');
        confirmButton.onclick = () => {
            const selectedWeightElement = newPopupElement.querySelector('input[name="weight"]:checked');
            const selectedWeight = selectedWeightElement.value;
            const selectedPrice = selectedWeightElement.getAttribute('data-price');
            const selectedWeightCategory = selectedWeightElement.getAttribute('data-weightcategory');

            this.cartController.addToCart(perfume, selectedPrice, selectedWeight, null, null, null, perfume.brand, selectedWeightCategory);
        };
    
        // Add a click event listener to the close button
        const closeButton = newPopupElement.querySelector('.popup-close');
        closeButton.addEventListener('click', () => {
            // Call the method to remove the perfume bottle from the Three.js scene
            if (this.popupElement) {
                this.threeController.removeCurrentPerfumeBottle();
                document.body.removeChild(this.popupElement);
                this.popupElement = null;
            }
        });
    
        // Replace the existing popup element, if any
        if (this.popupElement) {
            document.body.replaceChild(newPopupElement, this.popupElement);
        } else {
            document.body.appendChild(newPopupElement);
        }
    
        // Set the new popup element as the current one
        this.popupElement = newPopupElement;
        this.threeController.addPerfumeBottle(perfume.image, perfume.product);
    
        // Ensure the popup canvas is rendered by calling the renderer
    })
    .catch(error => {
        console.error('Error loading perfume prices:', error);
    });
}
    
    
    highlightSelectedPerfume(perfume) {
        const perfumeElement = document.querySelector(`[data-product="${perfume}"]`);
        if (!perfumeElement) {
            console.error('Perfume element not found for product:', perfume);
            return;
        }
    
        // Scroll to the perfume element to ensure it's visible.
        perfumeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        perfumeElement.style.backgroundColor = '#888888'; // Example: change background color
    
        // Add a class that triggers a CSS animation or highlight.
        perfumeElement.classList.add('nudge-animation');
    
        // Optionally, remove the highlight after some time.
        setTimeout(() => {
            perfumeElement.classList.remove('nudge-animation');
        }, 1000); // Adjust the time as needed.
    
        setTimeout(() => {
            perfumeElement.style.backgroundColor = ''; // Reset background color
        }, 3000);
    }
    
    
    // Assuming this method is within your CatalogueController class
    initBackToTopButton() {
        const backToTopBtn = document.getElementById('backToTopBtn');
        function animateScroll(element, startPosition, change, property) {
            const duration = 500; // Duration in ms
            let startTime = null;
        
            function animation(currentTime) {
                if (startTime === null) startTime = currentTime;
                const timeElapsed = currentTime - startTime;
                const progress = Math.min(timeElapsed / duration, 1);
        
                element[property] = startPosition + change * progress;
        
                if (timeElapsed < duration) {
                    requestAnimationFrame(animation);
                }
            }
        
            requestAnimationFrame(animation);
        }
        
        // Toggle button visibility and function based on scroll position
        const toggleBackToTopBtn = () => {
            const scrollVertical = this.perfumesListElement.scrollTop > 20;
            const scrollHorizontal = this.perfumesListElement.scrollLeft > 20;
        
            if (scrollVertical || scrollHorizontal) {
                backToTopBtn.style.display = 'block';
                backToTopBtn.textContent = scrollVertical ? 'Back to Top' : 'Back to Start';
            } else {
                backToTopBtn.style.display = 'none';
            }
        
            // Attach the appropriate scroll animation based on direction
            backToTopBtn.onclick = () => {
                if (scrollVertical) {
                    animateScroll(this.perfumesListElement, this.perfumesListElement.scrollTop, -this.perfumesListElement.scrollTop, 'scrollTop');
                } else if (scrollHorizontal) {
                    animateScroll(this.perfumesListElement, this.perfumesListElement.scrollLeft, -this.perfumesListElement.scrollLeft, 'scrollLeft');
                }
            };
        };
        
        this.perfumesListElement.onscroll = toggleBackToTopBtn;
        this.perfumesListElement.addEventListener('touchmove', toggleBackToTopBtn);
    }

}
