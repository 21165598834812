export default class SearchByName {
    constructor(catalogueController) {
        this.perfumesListElement = document.getElementById('perfumesList');
        this.catalogueController = catalogueController;
        this.setupEventListeners(); // Setup event listeners on instantiation
    }

    setupEventListeners() {
        document.addEventListener('DOMContentLoaded', () => {
            const searchByNameBtn = document.querySelector('.srcBtn');
            const searchInput = document.getElementById('searchInput');
            searchByNameBtn.addEventListener('click', () => {
                const query = searchInput.value;
                this.search(query).then(results => this.displaySearchResults(results));
            });
        });
    }

    async search(query) {
        try {
            const response = await fetch(`searchPerfumes.php?query=${encodeURIComponent(query)}`);
            return await response.json();
        } catch (error) {
            console.error('Error searching:', error);
            return []; // Return an empty array if there's an error
        }
    }
    
    

    displaySearchResults(results) {
        const resultsContainer = document.getElementById('results');
        resultsContainer.style.display = 'flex';
        resultsContainer.style.visibility = 'visible';
        resultsContainer.innerHTML = '';

        if (results.length === 0) {
            resultsContainer.innerHTML = '<p>No matches found.</p>';
            return;
        }

        results.forEach(perfume => {
            const perfumeElement = document.createElement('div');
            perfumeElement.classList.add('perfume-result');

            const h3 = document.createElement('h3');
            h3.textContent = `${perfume.product}`;
            h3.addEventListener('click', () => {
                this.catalogueController.displayPerfumes([perfume]);
                navigateToSection('catalogue');
                this.perfumesListElement.style.display = 'flex';
                setTimeout(() => {
                    this.catalogueController.highlightSelectedPerfume(perfume.product);
                }, 500);
                console.log(perfume)
                console.log(perfume.perfume)
            });

            perfumeElement.appendChild(h3);

            const details = document.createElement('p');
            let detailsContent = '';
            if (perfume.year) detailsContent += `<strong>Year:</strong> ${perfume.year} | `;
            if (perfume.notes) detailsContent += `<strong>Notes:</strong> ${perfume.notes} | `;
            if (perfume.topnotes) detailsContent += `<strong>Top Notes:</strong> ${perfume.topnotes} | `;
            if (perfume.middlenotes) detailsContent += `<strong>Middle Notes:</strong> ${perfume.middlenotes} | `;
            if (perfume.base) detailsContent += `<strong>Base:</strong> ${perfume.base}`;
            if (perfume.aromaticnotes) detailsContent += `<strong>Base:</strong> ${perfume.aromaticnotes}`;
            if (perfume.contains) detailsContent += `<strong>Base:</strong> ${perfume.contains}`;
            if (perfume.note) detailsContent += `<strong>Base:</strong> ${perfume.note}`;
            details.innerHTML = detailsContent.trim();
            perfumeElement.appendChild(details);

            resultsContainer.appendChild(perfumeElement);
        });
    }
}
